import React from 'react'
import { Link } from 'gatsby'

import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Hero title="Oops, page not found!">
      <p>Return to the <Link to="/">Home page</Link></p>
    </Hero>
  </Layout>
)

export default NotFoundPage
